<template>
  <y_layout>
    <YRouteTabs :menu="[
        {title:'待审活动',path:{name:'agentActive',params:$route.params}},
        {title:'代理管理',path: {name:'agentList',params:$route.params}},
        {title:'提现打款',path: {name:'cashList',params:$route.params}},
    ]"></YRouteTabs>
    <div style="margin-top: .5rem">
      <el-card>
        <router-view></router-view>
      </el-card>
    </div>

<!--    <el-row>-->
<!--      <el-col :span="1">-->
<!--        <div class="flex-def flex-zEnd" style="width: 100%;margin-top: 2.5rem">-->
<!--          <div class="flex-def flex-zCenter flex-cCenter flex-zTopBottom tab-box">-->
<!--            <div :class="$route.name === 'agentActive' ? 'active' : ''"-->
<!--                 @click="$router.push({name:'agentActive',params:$route.params})" class="y-pointer"-->
<!--                 style="padding: 1rem 0;">待<br>审<br>活<br>动-->
<!--            </div>-->
<!--            <div :class="$route.name === 'agentList' ? 'active' : ''"-->
<!--                 @click="$router.push({name:'agentList',params:$route.params})" class="y-pointer"-->
<!--                 style="padding: 1rem 0;">代<br>理<br>管<br>理-->
<!--            </div>-->
<!--            <div :class="$route.name === 'cashList' ? 'active' : ''"-->
<!--                 @click="$router.push({name:'cashList',params:$route.params})" class="y-pointer"-->
<!--                 style="padding: 1rem 0;border-top: 1px solid #ededed">提<br>现<br>打<br>款-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </el-col>-->
<!--      <el-col :span="23">-->
<!--        <el-card shadow="hover" :body-style="{padding:'1rem 1rem'}" style="width: 100%;min-height: 35rem">-->
<!--          <router-view/>-->
<!--        </el-card>-->
<!--      </el-col>-->
<!--    </el-row>-->
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";
import YRouteTabs from "@/components/YTabs/YRouteTabs";

export default {
  name: "index",
  components: {y_layout,YRouteTabs},
}
</script>

<style scoped>

.y-pointer:hover,
.active {
  color: #5D60F6;
}

.tab-box {
  border-radius: 10px 0 0 10px;
  border: 1px solid #ededed;
  font-size: 12px;
  background-color: #FFFFFF;
  width: 2rem;
  color: #888888;
  font-weight: 600;
}
</style>